import React from "react";
import Banner from "../components/Banner";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `WordPress Development Company`,
    content:
      "We are a top WordPress development company that creates custom websites for your organization using our flexible approach.",
  };
  const commonContent6 = {
    title: "Custom WordPress Development Services",
    para: "We prioritize producing dynamic and user-friendly websites and planning, developing, and customizing websites to help you increase your online presence. Our WordPress Website Design Services are focused on creating responsive and optimized websites.",
    img: ["what-service-image-wordpress.webp"],
    imageTitle: 'WordPress Development Agency',
    imageAlt: 'wordpress development services',
    content: [
      {
        title: "Custom WordPress",
        text: "We bring your vision to life by providing customized WordPress web development services.",
      },
      {
        title: "Enterprise-Oriented Solutions",
        text: "We develop dynamic, functional, and scalable WordPress website design services for organizations of all sizes.",
      },
      {
        title: "eCommerce Website",
        text: "Our skilled solutions are designed to increase your internet presence and conversions.",
      },
      {
        title: "WordPress CMS Development",
        text: "Our WordPress knowledge lets us easily create, customize, and maintain your content.",
      },
      {
        title: "Migration & Upgradation",
        text: "We periodically update your website to improve performance and ensure a smooth transition.",
      },
      {
        title: "Quality & Testing Services",
        text: "We perform thorough quality assurance and testing to provide a seamless customer experience.",
      },
      {
        title: "Support & Maintenance",
        text: "We provide ongoing support and maintenance to ensure your WordPress website runs properly.",
      },
      {
        title: "WordPress Consulting",
        text: "We consult and provide experienced guidance to help you enhance your online presence and make educated decisions.",
      },
      {
        title: "WordPress CMS Development",
        text: "We effortlessly build, customise and manage your content with our WordPress expertise.",
      },
      {
        title: "Dedicated WordPress Team",
        text: "We also provide a professional team to handle and optimize your WordPress website's functionality.",
      },
    ],
  };

  const commonContent = {
    title: "Industry-Specific WordPress Development Solutions",
    para: "Our WordPress website development company serves across business boundaries, aiding diverse sectors by customizing websites to match specific industry needs and create digital success.",
    img: ["who-needs-wordpress.webp"],
    imageTitle: 'WordPress Development Services',
    imageAlt: 'wordpress development firm',
    content: [
      {
        title: "Retail Industries",
        text: "Companies who want to offer exceptional customer experience to increase their online presence and drive business growth.",
      },
      {
        title: "Tech Companies",
        text: "Companies that wish to stay competitive in the sector should build robust websites, improve usability, and leverage platform flexibility.",
      },
      {
        title: "SaaS Companies",
        text: "Companies who wish to boost client retention rates by providing practical solutions such as multi-platform integrations, among others.",
      },
      {
        title: "E-Commerce Companies",
        text: "Companies who wish to boost client retention rates by providing practical solutions such as multi-platform integrations, among others",
      },
    ],
  };

  const commonContent2 = {
    title:
      "Partner with the Best WordPress development agency",
    para: "Our WordPress Agency digitally enhances your organization by raising its online presence, unlocking customization options, and increasing user engagement.",
    img: ["what-are-benefits-wordpress.webp"],
    imageTitle: 'Benefits of WordPress Development Services',
    imageAlt: 'wordpress development services',
    content: [
      {
        title: "Industrial Updates",
        text: "Our WordPress Website Design Services help your company stay ahead of the competition by staying current with the latest trends and technologies.",
      },
      {
        title: "Scalable Solution",
        text: "Our WordPress Web Development Services provide flexible platform solutions for increasing website functionality and incorporating future updates.",
      },
      {
        title: "Multi-Platform Integration",
        text: "Our WordPress Development Services seamlessly connects numerous platforms and optimizes procedures to create a cohesive environment.",
      },
      {
        title: "Affordable Solution",
        text: "WordPress is an open-source platform that eliminates the need for proprietary software, resulting in a cost-effective solution.",
      },
    ],
  };
  const commonContent3 = {
    title: "Why choose Octet as your WordPress development Agency?",
    linkText: ``,
    para: `We are a Custom WordPress Development Company, with expertise in creating high-quality, functioning websites using our complete and optimized solutions.`,
    img: ["why-opt-for-wordpress.webp"],
    imageTitle: 'Our WordPress Development Expertise',
    imageAlt: 'top wordpress development company',
    content: [
      {
        title: "User-Centric Approach",
        text: "We undertake rigorous user research and apply the findings to develop web solutions that engage users on your websites.",
      },
      {
        title: "Reliable Solution",
        text: "We ensure that our solutions are painstakingly and extensively tested to create secure, robust, user-friendly, and optimized websites.",
      },
      {
        title: "Continuous Support ",
        text: "We aspire for long-term collaboration, providing ongoing assistance and coaching to ensure your website is up-to-date and error-free.",
      },
      {
        title: "On-time Delivery",
        text: "Octet adheres to the deadline and delivers your websites within the agreed-upon timeframe using our efficient procedures for long-term collaboration. ",
      },
    ],
  };
  const commonContent4 = {
    title: "Process we follow at our WordPress Development Agency",
    para: "As the best WordPress Development Agency, we respond to businesses' specific needs, provide tailored WordPress Development Services, and design user-friendly, intuitive, and beautiful websites to help them stay ahead in the digital sphere.",
    img: ["how-we-conduct-wordpress.webp"],
    imageTitle: 'WordPress Development Process',
    imageAlt: 'wordpress development company in india',
    content: [
      {
        title: "1. Briefing",
        text: "The designer informs the UI developer on the team of the business objectives, project specifics, time frame, and client expectations.",
      },
      {
        title: "3. Testing",
        text: "Once the coding is complete, the UI developer performs thorough quality assurance checks to verify that no defects are left uncorrected.",
      },
      {
        title: "2. Development",
        text: "Based on their experience, the UI developer chooses the best framework and begins coding.",
      },
      {
        title: "4. Support",
        text: "Finally, our product engineering team hands over to the client and guides the client's team through the product's capabilities.",
      },
    ],
  };
  const commonContent5 = {
    title:
      "Benefits of Investing in Custom WordPress Development Services",
    para: "WordPress Development Services establishes a solid website presence and provides long-term benefits to your organization, increasing consumer loyalty.",
    content: [
      {
        title: "73% Prefers Responsive Site",
        text: "WordPress is an open-source program that allows to create responsive websites, and the majority of users choose it.",
      },
      {
        title: "97% Blogs Built Here",
        text: "Most websites and blogs are constructed on this adaptable platform due to its rich plugins, functionality, and interface.",
      },
      {
        title: "43% Market Share",
        text: "WordPress is a widely used platform that leads the industry due to its variety, robustness, and user confidence.",
      },
      {
        title: "42.8% Web Power",
        text: "The platform is constantly upgraded and improved, allowing for a more dynamic interface.",
      },
    ],
    desc: "*These statistics are from public sources; we do not own them*.",
  };
  const cta = {
    title: 'Struggling with a slow, outdated WordPress site?',
  };
  const cta2 = {
      title: 'Experience Excellence with Our WordPress Development Solutions!',
      contactText: 'Contact Us'
  };
  const cta3 = {
      title: "Leverage our Expertise in WordPress Development",
      contactText: 'Contact Us'
  };
  const cta4 = {
      title: "Build Robust Websites with Our WordPress Development Agency",
  };
  const cta5 = {
      title: "Let’s Collaborate to Dominate the Web",
  };
  const tabs = [
    {
      link: '/angularjs-development/',
      text: 'Angular Development'
    },
    {
      link: '/branding/',
      text: 'Branding'
    },
    {
      link: '/corporate-training-workshop/',
      text: 'UI UX Workshops and Training'
    },
    {
      link: '/data-visualisation/',
      text: 'Dashboards and Data Visualisation'
    },
    {
      link: '/design-audit/',
      text: 'Design Audit '
    },
    {
      link: '/design-systems/',
      text: 'Design System Engineering'
    },
    {
      link: '/digital-transformation/',
      text: 'Digital Transformation'
    },
    {
      link: '/product-development/',
      text: 'Product Development'
    },
    {
      link: '/rapid-prototyping/',
      text: 'Rapid Prototyping'
    },
    {
      link: '/reactjs-development/',
      text: 'ReactJS Development'
    },
    {
      link: '/ui-development/',
      text: 'UI Development'
    },
    {
      link: '/ui-ux-designing/',
      text: 'UI UX Designing'
    },
    {
      link: '/usability-testing/',
      text: 'Usability Testing'
    },
    {
      link: '/user-acquisition-consulting/',
      text: 'User Acquisition Consultation'
    },
    {
      link: '/user-research/',
      text: 'User Research'
    },
    {
      link: '/ux-consulting/',
      text: 'UX Consultation'
    },
    {
      link: '/vuejs-development/',
      text: 'VueJS Development'
    },
    {
      link: '/wordpress-development/',
      text: 'WordPress Development'
    },
  ];
  const projectData = [
    {
      title: "Auto Recruits",
      desc: "A Job-Portal Platform",
      Industry: "SaaS",
      firstAlt: "wordpress development solutions",
      firstTitle: "Job Applicants Overview",
      secondAlt: "best wordpress development company in india",
      secondTitle: "Applicants List",
      WhatWeDid: [
        {
            title: 'Product Development',
            link: '/product-development/'
        },
        {
            title: 'UI UX Designing',
            link: '/ui-ux-designing/'
        },
        {
            title: 'UI Development',
            link: '/ui-development/'
        }
      ],
      link: '/project/ui-ux-design-auto-recruits/',
      images: ["auto-recruits.webp", "auto-recruits-2.webp"],
    },
    {
      title: "BAA Platform",
      desc: "A Platform for Buying Agents",
      Industry: "SaaS",
      firstAlt: "wordpress development agency",
      firstTitle: "Manage Newsfeed",
      secondAlt: "wordpress development company",
      secondTitle: "Agenct's Directory",
      WhatWeDid: [
        {
          title: 'User Research',
          link: '/user-research/'
        },
        {
          title: 'User Research',
          link: '/user-research/'
        },
        {
            title: 'Design Audit',
            link: '/design-audit/'
        },
      ],
      link: '/project/ui-ux-design-baa-platform/',
      images: ["baa.webp", "baa-2.webp"],
    },
  ];

  const Questions = [
    "Is WordPress Development part of UI UX design?",
    "What kind of websites can be built with WordPress Development?",
    "How do you ensure the quality of my WordPress website?",
    "Can you integrate an ERP Or CRM tool with WordPress?",
    "How long do your WordPress development Services take?",
    "How much do you charge for your WordPress Development Services?",
  ];
  const clientsTitle =
    "Trusted By Clients Worldwide";
  const faqDes = `We understand you might have more questions regarding our WordPress Website Development Company and its services. Hence, we have answered some common questions asked by our clients. However, if you still have any doubts, please <a href="/contact-us/"> contact us.</a>`;

  const faqData = [
    {
      para: [
        `WordPress Development complements and supports the UI UX design but is not directly related. It turns design into functional websites through coding, platform integration, plugins, etc.`,
        `WordPress Development and <a target="_blank" rel="noreferrer" href="/ui-ux-designing/"> UI UX Designs</a> bring ideas to life and create exceptional user experience through seamless, intuitive, and visually appealing websites.`,
        `Additionally, you can also choose our <a target="_blank" rel="noreferrer" href="/design-audit/">design audit</a> services before starting with our UI UX Services to identify areas for improvement and their solutions, which will save you time and money during the <a target="_blank" rel="noreferrer" href="/ui-development/">development</a> process.`,
      ],
    },
    {
      para: [
        `WordPress Development can build various websites to suit your specific business requirements. Its flexibility and extensive plugin options can create a robust website.`,
        `Through our WordPress Website Design Services, you can create user-friendly websites for all types of businesses through its versatile approach and customizable features.`,
      ],
    },
    {
      para: [
        `At Octet, our experts use a systematic approach to building high-quality websites. We follow the best industry practices and stay updated with market trends to create robust and reliable websites.`,
        `We also conduct testing for bugs and errors during different phases, such as cross-browser testing, functionality testing, performance testing, etc.`,
        `We are the best WordPress Agency aiming to create a responsive website, optimize the website, and use modern methods to keep your website on par with the market trend.`,
      ],
    },
    {
      para: [
        `Yes, we are experienced in developing ERP and CRM tools and have successfully integrated them with enterprise and SaaS websites.`,
      ],
    },
    {
      para: [
        `The duration of our WordPress Development Services varies depending on several factors, such as the complexity of the business, business goals, and customizations needed.`,
        `We believe in a transparent approach and work closely with you to provide an accurate timeline based on your business needs.`,
        `In our WordPress Website Development Agency, we deliver high-quality websites within a reasonable time. Hence, we request that you contact us to know the exact time required for your project`,
      ],
    },
    {
      para: [
        `The cost of our WordPress Web Development Services depends on your project requirements, complexities, customizations needed, etc.`,
        `We aim to offer cost-effective solutions and fair pricing for our services. Hence, we request that you <a href="/contact-us/"> contact us</a> to get a detailed quotation.`,
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [{
                "@type": "Question",
                "name": "Is WordPress Development part of UI UX design?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "WordPress Development complements and supports the UI UX design but is not directly related. It turns design into functional websites through coding, platform integration, plugins, etc.
            WordPress Development and UI UX Designs bring ideas to life and create exceptional user experience through seamless, intuitive, and visually appealing websites.
            Additionally, you can also choose our design audit services before starting with our UI UX Services to identify areas for improvement and their solutions, which will save you time and money during the development process."
                }
              },{
                "@type": "Question",
                "name": "What kind of websites can be built with your WordPress Development Solutions?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "WordPress Development can build various websites to suit your specific business requirements. Its flexibility and extensive plugin options can create a robust website.
            Through our WordPress Website Design Services, you can create user-friendly websites for all types of businesses through its versatile approach and customizable features."
                }
              },{
                "@type": "Question",
                "name": "How does your WordPress Development Agency ensure the quality of my WordPress website?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "At Octet, our experts use a systematic approach to building high-quality websites. We follow the best industry practices and stay updated with market trends to create robust and reliable websites.
            We also conduct testing for bugs and errors during different phases, such as cross-browser testing, functionality testing, performance testing, etc.
            We are the best WordPress Agency aiming to create a responsive website, optimize the website, and use modern methods to keep your website on par with the market trend."
                }
              },{
                "@type": "Question",
                "name": "Can you integrate an ERP Or CRM tool with WordPress?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, we are experienced in developing ERP and CRM tools and have successfully integrated them with enterprise and SaaS websites."
                }
              },{
                "@type": "Question",
                "name": "How long do your WordPress development Services take?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The duration of our WordPress Development Services varies depending on several factors, such as the complexity of the business, business goals, and customizations needed.
            We believe in a transparent approach and work closely with you to provide an accurate timeline based on your business needs.
            In our WordPress Website Development Agency, we deliver high-quality websites within a reasonable time. Hence, we request that you contact us to know the exact time required for your project."
                }
              },{
                "@type": "Question",
                "name": "How much do you charge for your Custom WordPress Development Services?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The cost of our WordPress Web Development Services depends on your project requirements, complexities, customizations needed, etc.
            We aim to offer cost-effective solutions and fair pricing for our services. Hence, we request that you contact us to get a detailed quotation."
                }
              }]
            }                    
          `}
        </script>

      </Helmet>
      <Layout>
        <div className="service-detail-page">
          <Banner content={banner} page={"service"} />
          <CommonServiceComponent data={commonContent6} mode={"grey"} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent} mode={"light"} />
          <Cta mode='grey' data={cta} />
          <CommonServiceComponent data={commonContent2} mode={"dark"} />
          <CommonServiceComponent data={commonContent3} mode={"light"} />
          <Cta data={cta2} />
          <Clients title={clientsTitle} />
          <ServiceTab tabs={tabs} />
          <CommonServiceComponent data={commonContent4} mode={"light"} />
          <Cta mode='grey' data={cta3} />
          <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
            <div className='container'>
              <h2 className='lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.08em]'>Our Successful WordPress Projects</h2>
            </div>
            {projectData.map((data, index) => (
              <div key={index}>
                <ProjectSection num={index} lastLength={projectData} data={data} />
              </div>
            ))}
          </section>
          <Testimonials title={`Clients Take <span class='h1-span'>On Our Solutions</span>`} />
          <Cta data={cta4} />
          <CommonServiceComponent data={commonContent5} condition mode={"light"} />
          <ServiceTab tabs={tabs} mode={"split"} />
          <Faq Questions={Questions} section des={faqDes} link faqData={faqData} />
          <Cta mode="grey" data={cta5} />
          {/* <KnowMoreSection /> */}
        </div>
      </Layout>
    </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo title="WordPress development company | WordPress development solutions" description="Our WordPress Development Company delivers custom & responsive WordPress development solutions tailored to your business needs." />
)
